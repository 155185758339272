import React from 'react';
import VirtualHendiInterface from './Components/VirtualHendiInterface';

function App() {
  return (
    <VirtualHendiInterface />
  );
}

export default App;
